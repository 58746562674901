export const VISIBLE_FIELDS = ['name','value','tags','edit', 'delete'] ;
export const INITIAL_STATE = { id: null, name: null, value: null, category: null, tags: [], format: null, pid: null };
export default function(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case 'FETCH':
      return {
        ...state,
        loading: true
      };
    case 'FETCH_SUCCESS':
      return {
        ...INITIAL_STATE,
        ...payload,
        loading: false
      };
    case 'FETCH_FAILED':
      return {
        ...state,
        loading: false,
        error: payload
      };
    case 'SUBMIT': 
      return {
        ...state,
        ...payload,
        loading: true
      };
    case 'UPDATE_STATE':
      return {
        ...state,
        ...payload
      }
    case 'SET_FIELD':
      const { field, value } = payload
      return {
        ...state,
        [field]: value
      }
    default:
      return state;
  }
}
