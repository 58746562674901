import SecuredForm from '../components/SecuredForm'
import { RouterView } from '../utils';
import View from './View'

const usernameRegex = new RegExp(/^[A-Za-z]*[A-Za-z0-9]{2,}[A-Za-z0-9_]*$/)
const passwordRegex = new RegExp(/.{7,}/);

function SignupView({signup, history}) {
  const signupCallback = (fields) => {
    if (fields['password'] !== fields['passwordConfirm']) return

    delete fields['passwordConfirm']
    fields['valid'] = false
    signup(fields, history)
  }

  const regexMap = {
    username: (val, _) => !!val.match(usernameRegex),
    password: (val, _) => !!val.match(passwordRegex),
    passwordConfirm: (val, items) => !!val && val === items.password && !!val.match(passwordRegex)
  }

  const errorMap = {
    username: '',
    password: 'Password must be at least 7 characters.',
    passwordConfirm: 'Password fields must match.'
  }

  return (
    <View title='Sign up'>
      <SecuredForm onSubmit={ signupCallback } passwordConfirmation={true} 
        regexMap={regexMap} errorMap={errorMap} inputMap={
        {
          username: {label:'Username'},
          password: {label: 'Password', type: 'password'},
          passwordConfirm: {label:'Confirm Password', type: 'password'}
        }
      }/>
    </View>
  )
}

export default RouterView(SignupView);
