
function Level({left, right, classes, classRight='', classLeft=''}){
  return (
    <div className={'level '+classes}>
      <div className={'level-left'+classLeft}>
        <div  className='level-item'>
          { left && left.map((l, i) => 
             <div key={`l-${i}`}>{l}</div>
          )}
        </div>
      </div>
      <div className={'level-right'+classRight}>
        <div  className='level-item'>
          {right && right.map((r, i) => 
            <div key={`r-${i}`}>{r}</div>
          )}
        </div>
      </div>
    </div>
  )
}
export default Level;