import React from 'react'
import { useMediaQuery } from 'react-responsive'
import Note from '../components/Note'
import Nav from '../components/Nav'
import Level from '../components/Level'
import LinkButton from '../components/LinkButton'
import ContentWrapper from '../components/ContentWrapper';
import Toggleable from '../components/Toggleable';
import { RouterView, fetchObj, queryParam } from '../utils';
import DeleteButton from '../components/DeleteButton'
import AccessBox from '../components/AccessBox'

function NoteView({auth: {session, uid}, collection, collection: { loading }, fetchData, note: {format, pid, id, file}, setField, getPublicLink, removePublicLink,  match: {params: {slug, type}}}) {
  const [note, setNote] = React.useState({})
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
  const [noteLoading, setLoading] = React.useState(false)
  const [children, setChildren] = React.useState([])
  const [descendants, setDescendants] = React.useState([])

  React.useEffect( () => {
    if (pid)  setField('pid', null)
  }, [id]);

  React.useEffect( () => {
    const kind = note.name;
    if (children && children === true) 
      setChildren({r: fetchData(kind, `/q/${kind}`, session)})

    if (descendants && descendants === true) 
      setDescendants({r: fetchData(slug, `/q/${slug}`, session)})
    
  }, [note, children, descendants])

  React.useEffect( () => {
    if (collection[type] && Object.keys(collection[type]).length !== 0) {
      const notes = fetchObj(collection[type])
      const note = !!notes ? notes.find((r) => r.id === slug) : {}
      if (note && Object.keys(note).length === 0) {
        window.location.search.slice(1).split('=')
        fetchData(type, `/?f=id&v=${slug}`, session)
      } else {
        const isOwner = !!(note && note.owner === uid)
        setNote( () => setNote({
          ...note,
          isOwner: !!isOwner
        }))
      }
    } else if (!noteLoading){
      setLoading(true)
      fetchData(type, `/?f=id&v=${slug}`, session)
    }
  }, [collection, slug, type, pid])

  return (
    <div>
      {!format && <Nav category={type} slug={(note && note.name) || slug}/>}
      <section className="section is-medium">
        {!format && note && note.isOwner && <Level right={[
          <LinkButton noGroup to={`/${type}/${slug}/edit`} text='Edit' classes='is-link is-info is-inverted' icon='fas fa-pen-to-square'/>,
          <DeleteButton redirect noGroup category={type} id={note['id']} owner={note['owner']} />
        ]}/>}
        <Note {...{category: type, format, file, ...note}}/>
        { note && note.isOwner && <AccessBox pid={ note.pid } {...note} makeLink={() => getPublicLink(note.id, note.category, session)} deleteLink={() => removePublicLink(note.id, note.category, session)} />}
        { !format &&
          <Toggleable linkText='View Items' callback={(v) => setChildren(v)} content={
            note && note.name && <ContentWrapper key={'items-'+slug} loading={loading} title='Items' category={note && note.name} uid={uid} content={note && collection[note.name]} isMobile={isMobile}/>
          }/>
        }
        { !format &&
          <Toggleable linkText='View Descendants' callback={(v) => setDescendants(v)} content={
            <ContentWrapper key={'descendants-'+slug} loading={loading} route='categories' title='Descendants' category={slug} uid={uid} content={collection[slug]} isMobile={isMobile}/>
          }/>
        }
        { !format && note?.owner && <LinkButton noGroup to={`/${note.owner}?f=owner`} classes='is-link is-inverted is-small' text='view more by owner' /> }
      </section>
    </div>
  );
}

export default RouterView(NoteView);
