import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './actions';
import _ from 'underscore'

export const mapStateToProps = () => (p) => ({...p});

export const queryParam = (key, search) => {
  return Object.fromEntries(new URLSearchParams(search))[key]
}

export const RouterView = (view, propFunc=mapStateToProps) => {
  return connect(propFunc, actions)(withRouter(view));
}

export const StateView = (view, propFunc=mapStateToProps) => {
  return connect(propFunc, actions)(view);
}

export const find = (collection, val) => {
  return _.find(collection, (num) =>  num === val);
}

export const getAttr = (collection, field, value) => {
  return _.findWhere(collection, {[field]: value})
}

export const contentField = (content, field) => {
  if (!content) return []
  return _.uniq(_.flatten(Object.values(content).map((t) => t[field])))
}

export const fetchObj = (content) => {
  return typeof content === 'object' && content.length === undefined ? Object.values(content) : content;
}

export const highlightTarget = (idTarget, callback) => {
  const target = document.querySelector(idTarget);
  target.focus();
  target.select();
  if (callback) callback();
}