// Pass in Redux store's state to save it to the user's browser local storage
const SESSION = process.env.REACT_APP_SESSION_ID+'state'
export const saveState = (state) => {
  try
  {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(SESSION, serializedState);
    // console.log('saved!')
  }
  catch(error)
  {
    console.log('error saving '+ error)
    // We'll just ignore write errors
  }
};

// Loads the state and returns an object that can be provided as the
// preloadedState parameter of store.js's call to configureStore
export const loadState = () => {
  try
  {
    const serializedState = localStorage.getItem(SESSION);
    if (serializedState === null)
    {
      // console.log('invalid save ')
      return undefined;
    }
    return JSON.parse(serializedState);
  }
  catch (error)
  {
    // console.log('error loading '+ error)
    return undefined;
  }
};