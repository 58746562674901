import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reportWebVitals from './reportWebVitals';
import reducers from './reducers';
import App from './App';
import 'bulma/css/bulma.css'
import './index.css';
import reduxThunk from 'redux-thunk';
import throttle from 'lodash.throttle';
import _ from 'underscore';

import { saveState, loadState } from './utils/localStorage';

const store = createStore(reducers, loadState(), applyMiddleware(reduxThunk));

const SAVE_RATE = process.env.REACT_APP_SAVE_RATE;

store.subscribe(
	throttle(() => saveState({auth: store.getState()['auth']})
), SAVE_RATE)

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
