import React from 'react';
import { Link } from 'react-router-dom';
import { RouterView } from '../utils';

function MenuBar({location: {pathname}, auth: {uid, session, username}, history, logout}) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    window.addEventListener("click", (e) => {
      if(open && e.target.id !== 'menu') {
        setOpen(() => setOpen(false))  
      }
    });
  })

  if (!!pathname.match(/welcome/) )
    return <div></div>

  const mobileCallback = (e) => {
    e.preventDefault();
    setOpen(!open)
  }

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <Link className='navbar-item' to="/" >
          <h3 className="title is-3">KnowThat</h3>
        </Link>

        <a id='menu' role="button" onClick={mobileCallback} data-target="menuBar"
            className={`navbar-burger ${open ? ' is-active' : ''}`} 
            aria-label="menu" aria-expanded="false" >
          <span aria-hidden/>
          <span aria-hidden/>
          <span aria-hidden/>
        </a>
      </div>

      <div id="menuBar" className={`navbar-menu${open ? ' is-active' : ''}`}>
        <div className="navbar-start"/>
        <div className="navbar-end">
          { session && 
            <Link className={`navbar-item${open ? ' mobile' : ''}`} to="/Note/new" >
              New Note
            </Link>
          } { session &&
            <Link className={`navbar-item${open ? ' mobile' : ''}`} to={`${uid}?f=owner`} >
              Your Notes ({username})
            </Link>
          } { !session &&
            <div className={`navbar-item${open ? ' mobile' : ''}`}>
              <Link className="button is-primary" to="/signup" >
                <strong>Sign up</strong>
              </Link>
            </div>
          }
          <div className={`navbar-item${open ? ' mobile' : ''}`}>
            { !session && 
              <Link className='button is-light' to="/login" >
                Log in
              </Link>
            } { session &&
              <button className="button is-primary" onClick={() => logout(session, history)}>
                Logout
              </button> 
            }
          </div>
        </div>
      </div>
    </nav>
  )
};
export default RouterView(MenuBar);

