import Search from '../views/ResultsView';
import FormView from '../views/FormView';
import NoteView from '../views/NoteView';
import SignupView from '../views/SignupView';
import LoginView from '../views/LoginView';
import ProviderView from '../views/ProviderView';
import LandingView from '../views/LandingView';
import PasswordView from '../views/PasswordView';
import PublicView from '../views/PublicView';
import UnknownView from '../views/UnknownView';

const routes = [
	{ path: '/404', name: 'Not Found', component: UnknownView },
	{ path: '/@/:type/:pid', name: 'Public', component: PublicView },
	{ path: '/welcome', name: 'Welcome', component: LandingView },
	{ path: '/', name: 'Home', component: Search, private: true, redirect: '/welcome' },
	{ path: '/signup', name: 'Sign up', component: SignupView },
	{ path: '/login', name: 'Login', component: LoginView },
	{ path: '/password', name: 'Update Password', component: PasswordView },
	{ path: '/auth/:provider/callback', name: 'Callback', component: ProviderView },
	{ path: '/:type', name: 'New', component: Search, private: true  },
	{ path: '/:type/new', name: 'New', component: FormView, private: true  },
	{ path: '/:type/:slug', name: 'View', component: NoteView, private: true  },
	{ path: '/:type/:slug/edit', name: 'Edit', component: FormView, private: true  },
];

export default routes;