import { RouterView } from '../utils';
import LinkButton from "../components/LinkButton";

import React from 'react'

class LandingView extends React.Component {
  render(){
    return (
      <section className="hero is-primary is-large">
        <div className="hero-body">
          <div className="container has-text-centered">
            <p className="title">
              Knowthat
            </p>
            <p className="subtitle">
              An online knowledge base. 
              Because you should <em>Know That!</em>
            </p>
            <div className="box buttons is-group welcome-buttons has-background-primary-light is-centered">
              <LinkButton noGroup classes='is-rounded button is-info is-large' to='/signup' text='Sign up'/>
              <LinkButton noGroup classes='is-rounded button is-primary is-large is-outlined' to='/login' text='Login'/>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RouterView(LandingView);
