import { contentField, fetchObj } from '../utils'
import _ from 'underscore';

const INITIAL_STATE = { 
  // "categories":[{"name":"Note"},{"name":"Journal"},{"name":"Expense"}],
  // "tagsNote":[{"name":"Priority"}],
  // "tagsJournal":[{"name":"Happy"},{"name":"Sad"},{"name":"Angry"}],
  // "tagsExpense":[{"name":"Grocery"},{"name":"Shopping"},{"name":"Utility"}],
};
export default function(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case 'AUTH_LOGOUT':
      return {
        ...INITIAL_STATE
      }
    case 'FETCH':
      return {
        ...state,
        loading: true
      };
    case 'DELETE_ITEM':
      const delCate = fetchObj(state[payload.category]) || []
      return {
        ...state,
        loading: false,
        [payload.category]: [
          ...delCate.filter((i) => i.id !== payload.id)
        ],
        '~deleted': delCate.filter((i) => i.id === payload.id)
      }
    case 'DELETE_ITEM_FAILED':
      const lastDeleted = state['~deleted'][0]
      return {
        ...state,
        loading: false,
        [lastDeleted.category]: [
          ...state[lastDeleted.category],
          ...state['~deleted']
        ],
        '~deleted': []
      }
    case 'FETCH_COLLECT_SUCCESS':
      const cate = Object.keys(payload)[0]
      const valid = !!Object.values(payload)[0].length
      if (valid) {
        const tigCat = `tags${cate}`
        const tigs = contentField(Object.values(payload)[0],'tags')
        const tigCats = tigs ? tigs.map((tg) => Object.fromEntries([['name',tg]])) : [];

        // Gather all tag names, and find matching tags, then prioritizing tags with an id
        const oldTags = state[tigCat] ? state[tigCat] : [] 
        const names = _.compact(contentField([...tigCats, ...oldTags], 'name'))        
        const allTags = names.map((name) => oldTags.find((a) => a.name === name && a.id ) || oldTags.find((a) => a.name === name) || tigCats.find((a) => a.name === name))

        return { 
          ...state, 
          [cate]: [
            ...Object.values(payload)[0]
          ],
          [tigCat]: allTags,
          loading: false
        };
      }
      else {
        // setTimeout( () => {
        //   window.location.pathname = '404'
        // }, 500)
        return {
          ...state,
          loading: false,
          error: payload,
        }
      }
    case 'FETCH_COLLECT_FAILED':
      return {
        ...state,
        loading: false,
        error: payload
      };
    // Insert a single payload into collection cache
    case 'FETCH_SUCCESS':
      return {
        ...state,
        [payload.category]: [ 
          // Reject all that have same id as payload
          ..._.reject(state[payload.category], (n) => n.id === payload.id),
          // Insert payload
          payload
        ]
      }
    case 'SUBMIT':      
      const things = state[payload.category];
      const items = (typeof things === 'object' && things.length === undefined) ? Object.values(things) : things

      const idx = items && items.length > 0 && items.indexOf(items.find((i) => i.id === payload.id))
      const cats = items && items.length > 0 && idx !== -1 && items.map((item, index) => {
        if (index !== idx) return item
        return {
          ...item,
          ...payload
        }
      })
      
      return {
        ...state,
        [payload.category]: cats ? cats : items ? [...items, payload] : [payload],
      }
    default:
      return state;
  }
}
