import api, { toParams } from "./api"

// Actions are API Requests initiated by the user to the remove server.
const action = async (token, fields, method, url, success, failure) => {
  api.request(token, method, url, fields, failure, success)
}

// Retrieves data.
// e.g. GET /?f=category&v=Note
export const fetchData = (category, route, session) => async (dispatch) => {
	dispatch({ type: 'FETCH' });
  action(session, null, 'GET', route, (res) => {
    if (res.data && !res.data.error)
		  dispatch({ type: 'FETCH_COLLECT_SUCCESS', payload: {[category]: res.data} });
	  else 
		  dispatch({ type: 'AUTH_FAILED', payload: {...res.data, message: res.data.error} });
  }, (err) => dispatch({ type: 'FETCH_FAILED', payload: err }) )
};

// Create an item
// e.g. POST /tags/new
export const submitNew = (record, data, session, history) => async (dispatch) => {
	dispatch({ type: 'SUBMIT', payload: {...data, category: record} });
  action(session, data, 'POST', '/', (res) => {
    if (!res) 
		  dispatch({ type: 'SET_NOTICE', payload: {message: 'creation failed.'} });
    else {
      dispatch({ type: 'FETCH_SUCCESS', payload: res.data });
      const category = data.category
      history.push(category ? '/'+category : '/' )
    }
  },  (err) => dispatch({ type: 'FETCH_FAILED', payload: err }) )
}

// Update an item
// e.g. PUT /tags/1
export const submitUpdated = (id, record, data, session, history) => async (dispatch) => {
	dispatch({ type: 'SUBMIT', payload: {...data, category: record} });
  action(session, data, 'PUT', `/${id}`, (res) => {
    if (!res) 
		  dispatch({ type: 'SET_NOTICE', payload: {message: 'update failed.'} });
    else
      dispatch({ type: 'FETCH_SUCCESS', payload: res.data });
  },  (err) => dispatch({ type: 'FETCH_FAILED', payload: err }) )
	history.push(`/${record}`)
}

export const publicLink = (pid, record) => async (dispatch) => {
  action(null, null, 'GET', `/_/${record}/${pid}`, (res) => {
    if (!res)
      dispatch({ type: 'SET_NOTICE', payload: {message: 'Failed.'} });
    else
      dispatch({type: 'FETCH_SUCCESS', payload: res.data})
  }, (err) => {
    dispatch({ type: 'SET_NOTICE', payload: {message: 'Failed.'} });
  })
}

export const getPublicLink = (id, record, session) => async (dispatch) => {
  dispatch({ type: 'SET_FIELD', payload: {field: 'pid', value: true} });
  action(session, {}, 'POST', `/accessibility/${record}/${id}`, (res) => {
    if (!res)
      dispatch({ type: 'SET_NOTICE', payload: {message: 'public link failed.'} });
    else
      dispatch({type: 'FETCH_SUCCESS', payload: res.data})
  }, (err) => {
    dispatch({ type: 'SET_NOTICE', payload: {message: 'public link failed'} });
  })
}

export const removePublicLink = (id, record, session) => async (dispatch) => {
  dispatch({ type: 'SET_FIELD', payload: {field: 'pid', value: null}  });
  action(session, {}, 'DELETE', `/accessibility/${record}/${id}`, (res) => {
    if (!res)
      dispatch({ type: 'SET_NOTICE', payload: {message: 'public link failed.'} });
    else
      dispatch({type: 'FETCH_SUCCESS', payload: res.data})
    // dispatch({ type: 'SET_NOTICE', payload: {message: 'public link deleted.'} });
  }, (err) => {
    dispatch({ type: 'SET_NOTICE', payload: {message: 'public link failed'} });
  })
}

export const deleteItem = (hide, record, id, session, history, redirect=false) => async (dispatch) => {
	dispatch({ type: 'DELETE_ITEM', payload: {id, category: record} });
  const failure = () => dispatch({ type: 'DELETE_ITEM_FAILED', payload: {message: 'delete failed'} });
  if (hide){
    action(session, null, 'POST', `/hide/${record}/${id}`, (res) => {
      dispatch({ type: 'SET_NOTICE', payload: { message: res.data } });
    }, (_err) => {
      failure()
    })
  } else {
    action(session, {}, 'DELETE', `/${record}/${id}`, (res) => {
      dispatch({ type: 'SET_NOTICE', payload: { message: res.data } });
    }, (_err) => {
      failure()
    })
  }
	if (redirect)
	  history.push(`/${record}`)
} 

export const uploadFile = (data, session) => async (dispatch) => {
  const res = await api(session).post('/upload', data).catch((e) => handleError('FETCH_FAILED', e, dispatch));
  if (res && res.status === 200) {
    dispatch({ type: 'SET_NOTICE', payload: {message: `Uploaded file ${data.name}`} });
    dispatch({ type: 'UPDATE_STATE', payload: {file: {...res.data, size: data.size, name: data.name, type: data.type}} });
  } else {
    dispatch({ type: 'SET_NOTICE', payload: {message: 'Upload failed'} });
  }
}

export const signup = (fields, history) => async (dispatch) => {
	const data = toParams(fields)
  action(null, { data }, 'POST', '/signup', (res) => {
    if (res && res.data) {
      dispatch({ type: 'AUTH_SUCCESS', payload: res.data });
      history.push('/')
    } else 
      dispatch({ type: 'SET_NOTICE', payload: {message: 'Signup failed'} });
  }, (err) => {
    const res = err.response
    dispatch({type: 'SET_NOTICE', payload: res.data})
  })
}

export const authenticate = (fields, history) => async (dispatch) => {
	const data = toParams(fields)
  action(null, {data}, 'POST', '/login',(res) => {
    if (res && res.data) {
      dispatch({ type: 'AUTH_SUCCESS', payload: res.data });
      history.push('/')
    } else {
      dispatch({type: 'SET_NOTICE', payload: 'There was an error.'})
    }
  }, (err) => {
    const res = err.response
    dispatch({type: 'SET_NOTICE', payload: res.data})
  })
}

// Update Pasword
export const updatePassword = (fields, session, history) => async (dispatch) => {
	const data = toParams(fields)
  action(session, {data}, 'POST', '/password',(res) => {
    if (res && res.data) {
      dispatch({ type: 'AUTH_SUCCESS', payload: res.data });
      history.push('/')
    } else {
      dispatch({type: 'SET_NOTICE', payload: 'There was an error.'})
    }
  }, (err) => {
    const res = err.response
    dispatch({type: 'SET_NOTICE', payload: res.data})
  })
};

// Destroy the session
export const logout = (session, history) => (dispatch) => {
  action(session, {}, 'POST', '/logout',(_res) => {
    dispatch({ type: 'AUTH_LOGOUT', payload: { message: 'logout successfully.'} });
  }, (err) => {
    const res = err.response
    dispatch({type: 'SET_NOTICE', payload: res.data})
  })
	history.push('/')
};

// Acknowledge message
export const clearMessage = () => (dispatch) => {
	dispatch({ type: 'AUTH_ACK', payload: true });
};

export const setFormat = (format) => (dispatch) => {
	dispatch({ type: 'SET_FIELD', payload: {field: 'format', value: format} });
}

export const setField = (field, value) => (dispatch) => {
	dispatch({ type: 'SET_FIELD', payload: { field, value } });
} 

const handleError = (type, error, dispatch) => {
	if (error.response) {
		// The request was made and the server responded with a status code
		// that falls out of the range of 2xx
		console.log(error.response.data);
		console.log(error.response.status);

		let msg;
		try {
			msg = Object.values(error.response.data)[0]
		} catch(_er) {
			msg = JSON.stringify(error.response.data)
		}

		dispatch({ type: type, payload: {
			message: msg, 
			response: error.response
		}})
	} else if (error.request) {
		// The request was made but no response was received
		// `error.request` is an instance of XMLHttpRequest in the browser and an instance of
		// http.ClientRequest in node.js
		console.log(error.request);
		dispatch({ type: type, payload: {
			message: 'no response', 
			request: error.request }
		})
	} else {
		// Something happened in setting up the request that triggered an Error
		dispatch({ type: type, payload: {
			message: 'no error occurred' }
		})
	}
}