import React from 'react';
import Markdown from "./Markdown";
import { find } from '../utils'

function Note({name, value, category, tags, createdAt, updatedAt, format, attachment}) {
  const [content, setContent] = React.useState(null)

  React.useEffect(() => {
    if (!value) return
    const md = find(tags || [], 'markdown');
    setContent(() => setContent(
      !!md ? <Markdown children={value} options={ { className: 'content' } }/>
           : <div className="content" dangerouslySetInnerHTML={{__html: value || ''}}/>
      )
    )
  }, [tags, value])

  return (
    <div className="box">
      <h1 className="title">{name || 'Loading'}</h1>
      {!format && <h2 className="subtitle">{category || ''}</h2>}
      { content }
      <div className="tags">
        { tags && tags.map((tag) => <span className="tag is-info">{tag}</span>) }
      </div>

      { attachment &&
        <p className='block pointer'>
          <a href={attachment?.url} target='_blank'>
            {attachment?.name} - {attachment?.size} Bytes - {attachment?.type}
          </a>
        </p>
      }

      {createdAt && !format && <p>Created: {new Date(createdAt).toLocaleString()}</p>}
      {updatedAt && !format && <p>Last Updated: {new Date(updatedAt).toLocaleString()}</p>}
    </div>
  );
}

export default Note;
