import React from 'react'
import { useMediaQuery } from 'react-responsive'

import Flexbox from './Flexbox';
import Button from './Button';
import LinkButton from './LinkButton';
import Tag from './Tag';

export default function SelectionBox({title, category, categories, selected, setSelected, inclusive, setInclusive, noPrefix=false}) {
  const [visible, setVis] = React.useState(true);
  const toggle = () => setVis(() => setVis(!visible))

  const newRoute = !!title.match(/tags/i) ? `/tags${category}/new` : `/${category}/new`
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
  const tags = () => {
    if (!categories || categories.length === undefined ) return []
    const classSize = isMobile ? 'small' : 'large'
    return categories.map( (cat) => {
      let obj = typeof cat === 'string' ? {name: cat} : cat
      return <Tag key={`tag${obj.name}`} size={classSize} onClick={setSelected} selected={!selected.includes(obj.name)} {...obj}  />
    })
  }

  // const header = category.toLowerCase() === title.toLowerCase() ? title : `${category} ${title}`

  return (
    <div className='box'>
      <Button noGroup classes={`is-small is-pulled-right is-inverted ${visible ? ' is-primary ' : ' is-success'}`}
        icon={visible ? 'fas fa-arrow-up' : 'fas fa-arrow-down'} 
        onClick={(e) => {e.preventDefault(); toggle()}}
      />
      <p className="subtitle is-5 my-0">{title}</p>
      <Flexbox classes='buttons' justifyContent='flex-end' flexWrap='wrap' alignItems='center'>
        <Button noGroup classes={`heading is-small is-info ${inclusive ? '' : 'is-inverted'} ${!setInclusive ? 'is-hidden' : ''}`} 
          icon={inclusive ? 'fas fa-circle' : 'fa-regular fa-circle'} 
          text={inclusive ? 'AND' : 'OR'} 
          onClick={(e) => {e.preventDefault(); setInclusive(!inclusive)}}
        /> 
        { visible && <LinkButton noGroup to={newRoute} classes='is-pulled-right is-info is-medium is-inverted' icon='fa fa-circle-plus'/> }

      </Flexbox>
      <div className='tags'>
        { visible && tags() }
        { !visible && 
          selected.map( (obj) => { return <Tag key={`tag${obj}`} size='small' onClick={setSelected} name={obj} {...obj}  /> }) 
        }
      </div>
    </div>
  )
}