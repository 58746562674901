import { combineReducers } from 'redux';
import noteReducer from './noteReducer';
import authReducer from './authReducer';
import collectionReducer from './collectionReducer';

export default combineReducers({
	note: noteReducer,
	auth: authReducer,
	collection: collectionReducer
});
