import React from 'react'
import FormFieldIcon from './FormFieldIcon'
import ErrorBox from './ErrorBox'
import Button from './Button'
import _ from 'underscore'

export default function SecuredForm({onSubmit, passwordConfirmation, inputMap, regexMap, errorMap}) {
  const [fields, setFields] = React.useState({valid: false, fieldErrors: {}})

  let updateField = (field, value) => {
    let valid = true;
    let validFields = []
    const items = { ...fields, [field]: value }
    if (passwordConfirmation) {
      validFields = _.compact(Object.keys(items).map(
        (item) => regexMap[item] && 
          [item, !regexMap[item](items[item], items) ? errorMap[item] : false]
      ))
      validFields = Object.fromEntries(validFields)
      valid = _.every(Object.keys(regexMap), (item) => validFields[item] === false)
    }

    setFields({
      ...items,
      valid: valid,
      fieldErrors: validFields,
      notice: !valid ? _.filter(Object.values(validFields),(v) => !!v).join('<br/>') : ''
    })
  }
  const iconClass = (field) =>  fields.fieldErrors[field] === undefined ? 'has-text-light' : fields.fieldErrors[field] === false ? 'has-text-success' : 'has-text-danger' 
  return (
    <div>
      { Object.keys(inputMap).map((input) => {
          return (
            <FormFieldIcon key={input} label={inputMap[input].label} target={input} icon='fa-check' 
              iconClass={iconClass(input)}>
              <input id={input} className='input' type={inputMap[input].type || 'text'} autoComplete={input} 
                required placeholder='' value={fields[input] ||''} 
                onChange={(e) => updateField(input, e.target.value)}/>
            </FormFieldIcon>
          )
        }
      )}
      <ErrorBox text={fields.notice} />
      <Button text='Submit' type='submit' classes='is-info' disabled={!fields.valid} onClick={ () => onSubmit(fields)} onSubmit={() => onSubmit(fields)}/>
    </div>
  )
}