import { Link } from 'react-router-dom';

export default function LinkButton({text, to, classes, icon, noGroup=false}) {
  if(icon) icon = <span className="icon"><i className={`${icon} fa-lg`} /></span>

  const link = (content) => (
    <Link className={`button ${classes}`} to={to} >
      { content }
    </Link>
  )

  if (noGroup && !icon) return <div>{link(text)}</div>
  if (noGroup && !text) return <div>{link(icon)}</div>
  if (noGroup) return link(<span><span>{text}</span>{icon}</span>)

  if (!text && icon)
    return (
      <p className='buttons'>
        { link(icon) }
      </p>
    )

  return (
    <p className='buttons'>
      { link(
        <span><span>{text}</span>{icon}</span>
      ) }
    </p>
  )
}