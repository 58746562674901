import React from 'react'
import Button from './Button';

export default function Uploader({onDone}) {
  const [file, setFile] = React.useState({})

  React.useEffect(() => {
    const fileInput = document.querySelector('#uploader input[type=file]');
    fileInput.onchange = () => {
      if (fileInput.files.length > 0) {
        setFile(fileInput.files[0])
      }
    }
  }, [])

  const uploadFile = () => {
    if (!file.arrayBuffer) return setFile({name: 'Nothing selected'})
    
    var reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = () => {
      onDone({
        file: btoa(reader.result),
        type: file.type,
        size: file.size,
        name: file.name
      })
    };
    reader.onerror = () => {
      console.log('there are some problems');
    };
  }

  return (
    <React.Fragment>
      <div className='control file' id='uploader' >
        <input className="input" type="file" name='file' placeholder="Select file"/>
      </div>
      <div className='control'>
        <Button classes='is-info' text='Upload' onClick={uploadFile}/>
      </div>
    </React.Fragment>
  )
}