import React from 'react'
import Button from './Button'
import Modal from './Modal'
import { RouterView } from '../utils';

function DeleteButton({category, id, owner, deleteItem, auth: {uid, session}, history, hideText=false, redirect=false}){
  const [dele, setDele] = React.useState(false)
  const phrase = uid === owner ? 'Delete' : 'Hide'
  const icon = uid === owner ? 'fas fa-trash' : 'fas fa-eye-slash'
  const sure = uid === owner ? "Are you sure? Recovery is not possible." : "Are you sure?"

  const onDelete = () => {
    deleteItem(uid !== owner, category, id, session, history, redirect);
    setDele(() => setDele(false))
  }

  return (
    <React.Fragment>
      { id && 
        <Button noGroup flip text={!hideText && phrase} classes='is-link is-danger is-inverted mx-6' icon={icon} onClick={() => setDele(true)}/>
      }
      { id && 
        <Modal title={`${phrase} ${id}`} content={sure} stay='Keep' leave={phrase} onDecline={onDelete} onAccept={() => setDele(false)} isActive={dele}/>
      }
    </React.Fragment>

  )
}
export default RouterView(DeleteButton);
