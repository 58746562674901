const INITIAL_STATE = { username: null, session: null, loading: false, message: null };
export default function(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case 'LOGIN':
      return {
        ...INITIAL_STATE,
        ...payload
      };
    case 'AUTH':
      return {
        ...INITIAL_STATE,
        provider: payload,
        loading: true
      };
    case 'AUTH_SUCCESS':
      if (state.lastLocation)
        setTimeout(() => window.location.pathname = state.lastLocation, 500)
      return { 
        ...state, 
        message: 'login successful',
        ...payload,
        provider: null,
        loading: false,
        lastLocation: null
      };
    case 'AUTH_FAILED':
      if (payload.redirect)
        setTimeout(() => window.location.pathname = payload.redirect, 500)
      return {
        ...state,
        loading: false,
        message: 'an error occurred',
        ...payload,
        provider: null,
        lastLocation: window.location.pathname
      };
    case 'AUTH_ACK':
      return {
        ...state,
        message: null,
        error: null
      };
    case 'SET_NOTICE':
      return {
        ...state,
        ...payload
      };
    case 'AUTH_LOGOUT':
      return {
        ...INITIAL_STATE,
        loading: false,
        session: null, 
        username: null,
        ...payload
      };
    default:
      return state;
  }
}
