import SecuredForm from '../components/SecuredForm'
import View from './View'
import { RouterView } from '../utils';

const passwordRegex = new RegExp(/.{7,}/);

function PasswordView({auth: {session, username}, history, updatePassword}) {
  const callback = (fields) => {
    if (fields.passwordNew !== fields.passwordConfirm) return

    delete fields['passwordConfirm']
    fields.username = username
    updatePassword(fields, session, history)
  }

  const regexMap = {
    password: (_val, _items) => true,
    passwordNew: (val, _items) => !!val.match(passwordRegex),
    passwordConfirm: (val, items) => !!val && val === items.passwordNew && !!val.match(passwordRegex)
  }
  const errorMap = {
    password: '',
    passwordNew: 'Password must be at least 7 characters.',
    passwordConfirm: 'Password fields must match.'
  }

  return (
    <View title='Update Password'>
      <SecuredForm onSubmit={ callback } passwordConfirmation={true} regexMap={regexMap} errorMap={errorMap} inputMap={
        {
          password: {label: 'Password', type: 'password'},
          passwordNew: {label:'New Password', type: 'password'},
          passwordConfirm: {label:'Confirm New Password', type: 'password'}
        }
      }/>
    </View>
  );
}

export default RouterView(PasswordView);
