import axios from 'axios';

const base = process.env.REACT_APP_BASE_API
const prefix = process.env.REACT_APP_TOKEN

const Api = (token=null) => {
  const defaultOptions = {
    baseURL: base,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  if (!!token) {
    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      config.headers.Authorization =  `${prefix}=${token}`;
      return config;
    });
  }

  return instance;
};
// export default Api;

let loading = false;

function stopLoading() {
  loading = false
}

export const toParams = (data) => {
  return btoa(encodeURIComponent(JSON.stringify(data)))
} 

export const fromParams = (data) => {
  return JSON.parse(decodeURIComponent(atob(data)))
}

export default {
  request: (token, method, url, payload=null, onError, onDone) => {
    if (loading && loading.includes(url)) 
      return false
    
    if (loading)
      loading.push(url);
    else 
      loading = [url]

    Api(token).request({
      url: url,
      method: method,
      data: payload
    }).then(onDone)
    .catch(onError)
    .finally(stopLoading)
  }
}
