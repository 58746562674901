import React from 'react'
import Card from './Card'
import Loading from './Loading'

export default function List({category, uid, content, loading}) {
  if( !content && loading)
    return <Loading text='Loading Data'/>

  return (
    <React.Fragment>
      { content && content.map((item) => <Card key={`c-${item.id || item.name}`} {...item} category={category} isOwner={item.owner === uid} />) }
      { !!content && content.length < 1 && <div className='box is-shadowless'>No Results</div>}
    </React.Fragment>
  )
}