import React from 'react';
import { RouterView } from '../utils';

import Message from './Message'

function MessageWrapper({auth: { message, error }, clearMessage}) {
  return (
    <React.Fragment>
      {message && <Message text={message } close={clearMessage}/>}
      {error && <Message text={error} kind='is-danger' close={clearMessage}/>}
    </React.Fragment>
  )
}
export default RouterView(MessageWrapper);
