import React from 'react'

import Form from '../components/Form'
import Nav from '../components/Nav'
import Loading from '../components/Loading'
import { RouterView, contentField, fetchObj } from '../utils';
import {INITIAL_STATE} from '../reducers/noteReducer';

function FormView({collection, collection: {categories}, note: { file }, fetchData, submitNew, submitUpdated, uploadFile,auth: {uid, session}, history, match: {params: {slug, type}}}) {
  const [note, setNote] = React.useState({})
  const [options, setOptions] = React.useState(undefined)
  React.useEffect( () => {
    if (slug && Object.keys(collection[type]).length !== 0) {
      const notes = fetchObj(collection[type])
      const note = !!notes ? notes.find((r) => r.id === slug) : {}
      if (Object.keys(note).length === 0) {
        fetchData(type, `/?f=id&v=${slug}`, session)
      } else {
        const isOwner = note.owner === uid
        const userTags = type ? contentField(collection[`tags${type}`],'name') : []
        const availTags = (note && note.tags) ? [...new Set([...note.tags, ...userTags])] : userTags      
        const availCats = [...new Set([...fetchObj(categories || []).map((cat) => cat.name), note && note.category, type])];
        setNote( () => setNote({
          ...note,
          isOwner: slug ? !!isOwner : true,
        }))
        setOptions( () => setOptions({
          optionTags: availTags,
          optionCategories: availCats
        }))
      }
    } else if(slug){
      fetchData(type, `/?f=id&v=${slug}`, session)
    } else {
      const userTags = type ? contentField(collection[`tags${type}`],'name') : []
      const availTags = (note && note.tags) ? [...new Set([...note.tags, ...userTags])] : userTags      
      const availCats = [...new Set([...fetchObj(categories || []).map((cat) => cat.name), note && note.category, type])];
      setOptions( () => setOptions({
        optionTags: availTags,
        optionCategories: availCats
      }))
      setNote( () => setNote({isOwner: !slug}))
    }
  }, [collection,categories, slug, type])

  if (options === undefined) 
    return <Loading text='Loading Data'/>

  if (slug && note && !note.isOwner) 
    return (
      <div>  
        <Nav category={type} slug={note.name || 'New'}/>
        <section className="section is-medium">
          <p>Cannot edit. You do not have permission.</p>
        </section>
      </div>
    ) 

  const onSubmit = (data) => {
    const { category } = data;
    const payload = { ...data, file: file }
    if (category)
      return (!slug ? submitNew(category, payload, session, history) : submitUpdated(slug, category, payload, session, history))
    console.log(`${category} missing in ${payload}`)
  }

  return (
    <div>
      <Nav category={type} slug={!note ? 'New' : note.name}/>
      <section className="section is-medium">
        <Form onSubmit={onSubmit} uploadFile={(blob) => uploadFile(blob, session)} fileLink={file?.url} action={!slug ? 'New' : 'Editing '} {...options}
          formData={slug ? {...{...INITIAL_STATE, category: type, ...note}} : {...INITIAL_STATE, category: type} } 
        />
        <p>{note && note.createdAt && 'Created: '}{note && note.createdAt && new Date(note.createdAt).toLocaleString()}</p>
        <p>{note && note.updatedAt && 'Last Updated: '}{note && note.updatedAt && new Date(note.updatedAt).toLocaleString()}</p>
      </section>
    </div>
  );
}

export default RouterView(FormView);
