import React from 'react'
import LinkButton from "./LinkButton";
import DeleteButton from "./DeleteButton";
import Flexbox from "./Flexbox";

const TITLE_LENGTH = 20

export default function Card({name='Name', category, id, value='rawr', tags=[], owner, isOwner=false, updatedAt}) {
  const [opened, setOpened] = React.useState(false)
  const iClass = opened ? 'fa-angle-up' : 'fa-angle-down'

  let title = name.substring(0, Math.min(TITLE_LENGTH,name.length))
  if (title.length < name.length) title += '...' 
  const content = () => {
    if (!opened) return
    return (
      <div className="card-content">
        <strong>{name}</strong>
        <div className='content' dangerouslySetInnerHTML={{__html: value}}/>
      </div>
    )
  }

  const actionButtons = () => {
    const actions = [ 
      <LinkButton key={`edit-${id}`} noGroup to={`/${category}/${id}/edit`} text='Edit' classes='is-info is-inverted' icon='fas fa-pen-to-square'/>,
      <DeleteButton key={`del-${id}`} noGroup category={category} id={id} owner={owner} />
    ]
    return (
      <footer className="card-footer buttons is-flex is-justify-content-space-around">
        <p>{updatedAt && 'Last Updated: '}{updatedAt && new Date(updatedAt).toLocaleString()}</p>
        {isOwner && actions.map((e) => e)}
        {!isOwner && actions[1]}
      </footer>
    )
  }

  return (
    <div className="card my-5">
      <header className="card-header"  onClick={() => setOpened(!opened)}>
        <p className="card-header-title">
          {id && <LinkButton to={`/${category}/${id}`} text={title} classes='is-ghost'/>}
          {!id && <LinkButton to={`/${category}/new`} text={title} classes='is-ghost'/>}
        </p>
        <button className="card-header-icon" aria-label="more options" onClick={() => setOpened(!opened)}>
          <span className="icon">
            <i className={`fas ${iClass}`} aria-hidden="true"></i>
          </span>
        </button>
      </header>
      <Flexbox flexWrap='wrap'>
        { tags.map((n) => <span key={n} className='tag is-small flex'>{n}</span>)}
      </Flexbox>
      { content() }
      { opened && actionButtons() }
    </div>
  )
}