import {StateView} from '../utils'

const madeBy = process.env.REACT_APP_MADE_BY
const link = process.env.REACT_APP_MADE_BY_LINK

function Footer({format}) {

  if (!!format) return <div/>

  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          Made by <a href={link}>{madeBy}</a>
        </p>
      </div>
    </footer>
  )
}
export const mapStateToProps = () => ({note}) => ({ format: note.format });
export default StateView(Footer, mapStateToProps);
