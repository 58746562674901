import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../actions';

class ProviderView extends React.Component {
	componentDidMount(){
    let provider = this.props.match.params.provider;

		let params = new URLSearchParams(window.location.toString().split('#')[1])
    const state = params.get('state');
		const ruuid = localStorage.getItem(`${provider[0]}-oauth-id`);
    if (!ruuid)
      console.log('oauth state missing')
		const validResponse = ruuid && !!state.match(ruuid);
		const token = params.get('access_token')

    localStorage.setItem(`${provider[0]}-oauth-id`, null);
    if (validResponse && token)
      this.props.fetchUser('google', token, this.props.history);
    else
      this.props.history.push('/home')
	}

	render() {		
		return (
			<div style={{ textAlign: 'center', paddingBottom: 20 }}>
				<h3>Loading your account, please wait.</h3>
        <progress className="progress is-large is-info" max="100"/>
			</div>
		);
	}
}
// State is from reducer, auth is property on authReducer
function mapStateToProps({ auth }) {
	return { auth };
}

export default connect(mapStateToProps, actions)(withRouter(ProviderView));
