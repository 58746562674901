import React from 'react'
import Button from './Button'
import LinkButton from './LinkButton'
import TableWrapper from './TableWrapper'
import List from './List'
import Flexbox from './Flexbox';

import { fetchObj } from '../utils'
import _ from 'underscore'

const filterTag = (arr, tag) => arr.filter(e => e.tags && e.tags.includes(tag))

export default function ContentWrapper({path, route, category, uid, content, selected, inclusive=false, isMobile, loading=false, title, children}) {
  const [view, setView] = React.useState(false)
  const [filtered, setFiltered] = React.useState([])
  const [count, setCount] = React.useState(null)

  React.useEffect(() => {
    let filter = []
    let items = fetchObj(content)

    if (selected && items) {
      if (inclusive){
        // Select nested tags INCLUSIVE Searching for all matches
        for (let i = 0; i < selected.length; i++) {
          items = filterTag(items, selected[i])
        }
      }
      // Without any selections set filter to be content
      if (selected.length === 0)
        filter = items
  
      // Select tags EXCLUSIVELY Searching for any match
      for (let i = 0; i < selected.length; i++) {
        filter.push(...filterTag(items, selected[i]))
      }
      filter = [...new Set(filter)]; // make unique
    } else {
      filter = items
    }
    setFiltered(()=> setFiltered(filter))
    setCount(() => setCount(filter ? filter.length !== content.length ? `${filter.length}/${content.length}` : filter.length : null))
  }, [selected, content, category, inclusive])

  const titleDiv = route !== category ? 
    <LinkButton noGroup text={title || category} to={`/${category}/`} classes='has-text-left flex-2 is-ghost is-size-3'/>
     : <p className='flex-2 is-size-3'>{title || category}</p>

  const contentView = () => {
    if ((!loading) && ((filtered && filtered.length === 0) || (content === undefined)))
      return <p className='box notification is-italic'>There is no content yet.</p>

    if (isMobile) return <List loading={loading}  category={category} uid={uid} content={filtered} />
    if (view)
      return <List loading={loading} category={category} uid={uid} content={filtered}/>
    else
      return <TableWrapper uid={uid} loading={loading} route={route} category={category} content={filtered} selected={selected} ins={inclusive}/>
  }

  const actionButtons = () => {
    const actions = _.compact([ 
      path && <LinkButton noGroup key={`info-${category}`} to={`/${path}`} icon='fas fa-circle-info' classes='is-inverted is-info is-pulled-left' />,
      <LinkButton noGroup key={`new-${category}`} to={`/${category}/new`} classes='is-medium is-primary is-inverted' icon='fas fa-square-plus'/>,
      <Button key={`${category}-view`} noGroup onClick={() => setView(!view)} classes='is-medium is-info is-inverted' icon={`fas ${view ? 'fa-table-columns' : 'fa-table-list'}`}/>
    ])
    if (isMobile) 
      return _.initial(actions)

    return <div className='buttons'>
      {actions.map((e) => e)}
    </div>
  }

  const suffix = ` item${parseInt(content && content.length) > 1 ? 's' : ''}`

  return (
    <div className='box'>
      <div>
        { titleDiv }
      </div>
      <Flexbox justifyContent='space-between' flexWrap='wrap' alignItems='center'>
        {!!count && (content && content.length) > 0 ?  <p className='has-text-left is-italic	'>({count}{suffix})</p> : <div/>}
        {actionButtons()}
      </Flexbox>
      { contentView() }
      { children }
    </div>
  )
}