function Message({text, close, kind='is-info'}) {
  return (
    <article className={`message block mx-4 ${kind}`}>
      <div className="message-body">
        {text}
        <span className="message-button icon" aria-label="delete" onClick={close}>
          <i className="fas fa-xmark"></i>
        </span>
      </div>
    </article>
  )
}
export default Message;