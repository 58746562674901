import React from 'react'
import FormField from './FormField'
import Modal from './Modal'
import Button from './Button'
import Uploader from './Uploader';

// {tags: ["One", "Two"], name: "test", category: "Journal", value: "descript"}
function Form({formData, action, onSubmit, optionTags, optionCategories, uploadFile, fileLink}) {
  const [tags, setTags] = React.useState(optionTags || []);
  const [categories, setCategories] = React.useState(optionCategories || []);
  const [fields, setFields] = React.useState(formData);
  const selTags = fields.tags;
  let handleTag = (tag) => {
    if (selTags.includes(tag)) 
      updateForm({'tags': [...selTags.filter((e) => e !== tag)], newTag: null})
    else 
    updateForm({'tags': [...selTags, tag], newTag: null})
  } 
  handleTag = handleTag.bind(this);

  React.useEffect(() => {
    const params = window.location.search.substr(1);
    if (!params) return
    
    const urlParams = window.location.search.substr(1).split('=').map((i) => decodeURIComponent(i))
    setFields({
      ...fields,
      ... Object.fromEntries([urlParams])
    })
  }, [])

  let updateForm = (payload) => {
    setFields({
      ...fields,
      ...payload
    })
  }
  updateForm = updateForm.bind(this);
  
  let updateField = (field, value) => {
    setFields({
      ...fields,
      [field]: value
    })
  }
  updateField = updateField.bind(this);

  const reset = () => window.history.back();

  return (
    <div className="box notification">
      <div className='level'>
        <div className='level-left'>
          <div className='level-item'>
            <p className='subtitle is-5'>{action} Form</p>
          </div>
        </div>
      </div>
      <FormField label='Name'>
        <div className='control'>
          <input className="input" type="text" placeholder='' value={fields['name']||''} onChange={(e) => updateField('name', e.target.value)}/>
        </div>
      </FormField>
      <FormField label='Category' target='sel-category' >
        <div className='control'>
          <div className="select" value={fields['category']} defaultValue={fields['category']} onChange={(e) => updateField('category', e.target.value)}>
            <select id='sel-category'>
              { categories.map(cat => <option selected={cat===fields['category']} key={cat}>{cat}</option>)}              
            </select>
          </div>
        </div>
      </FormField>
      <FormField label='New Category' classes='has-addons'>
        <div className='control'>
          <input className="input is-small" type="text" placeholder='' value={fields['newCategory']||''} onChange={(e) => updateField('newCategory',e.target.value)}/>
        </div>
        <div className='control'>
          <Button icon='fas fa-plus'
            classes='is-info is-small' 
            onClick={ 
              () => {
                const newCat = fields['newCategory']
                setCategories([...categories, newCat])
                setTimeout(() => { updateForm({'category': newCat, 'newCategory': null}) }, 1)
              }
            }
          />
        </div>
      </FormField>
      <FormField label='Notes'>
        <div className='control'>
          <textarea className="textarea" placeholder="e.g. Hello world" value={fields['value']||''} onChange={(e) => updateField('value', e.target.value)}/>
        </div>
      </FormField>
      <FormField label='Tags'>
        <div className="tags">
          { tags.map((t) => <span key={t} className={`tag is-info ${!fields['tags'].includes(t) ? 'is-light':''}`} onClick={() => handleTag(t)}>{t}</span> )}          
        </div>
      </FormField>
      <FormField label='New Tag' classes='has-addons'>
        <div className='control'>
          <input className="input is-small" type="text" placeholder='' value={fields['newTag']||''} onChange={(e) => updateField('newTag',e.target.value)}/>
        </div>
        <div className='control'>
          <Button icon='fas fa-circle-plus'
            classes='is-primary is-small' 
            onClick={ 
              () => {
                const newTag = fields['newTag']
                if (tags.indexOf(newTag) !== -1) return updateField('newTag', null)
                setTags([...tags, newTag])
                setTimeout(() => { handleTag(newTag) }, 1)
              }
            }
          />
        </div>
      </FormField>
      <FormField label='Upload File' classes='has-addons'>
        <Uploader onDone={uploadFile}/>
      </FormField>
      { fileLink && 
        <FormField label='Uploaded'>
          <div>
            <a href={fileLink}>{fileLink}</a>
          </div>
        </FormField>
      }

      <div className='block'>
        <p className='is-size-7'>Select and upload before saving.</p>
      </div>
      <FormField classes='is-grouped buttons is-justify-content-space-between'>
        <div className='control'>
          <Button text='Cancel' noGroup classes='is-danger is-pulled-right'  onClick={()=> updateField('exit', true)} />
        </div>
        <div className='control'>
          <Button text='Save' noGroup classes='is-primary' icon='fas fa-floppy-disk' onClick={()=> onSubmit(fields)}/>
        </div>
      </FormField>
      <Modal title='Cancel' content='Are you sure?' onAccept={() => updateField('exit', false)} onDecline={reset} isActive={fields.exit}/>
    </div>
  );
}

export default Form;
