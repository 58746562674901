import React from 'react'
import SecuredForm from '../components/SecuredForm'
import View from './View'
import { RouterView } from '../utils';

function LoginView({authenticate, history}) {
  return (
    <View title='Login'>
      <SecuredForm onSubmit={ (d) => authenticate(d, history) } inputMap={
        {
          username: {label:'Username'},
          password: {label: 'Password', type: 'password'}
        }
      }/>
    </View>
  )
}

export default RouterView(LoginView);
