function Modal({title, content, onAccept, onDecline, isActive=false, stay='Keep Editing', leave='Exit'}) {
return (
  <div className={`modal ${isActive ? 'is-active' : ''}`}>
    <div className="modal-background"></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{title}</p>
      </header>
      <section className="modal-card-body">
        {content}
      </section>
      <footer className="modal-card-foot">
        <button className="button" onClick={onAccept}>{stay}</button>
        <button className="button is-pulled-right is-danger" onClick={onDecline}>{leave}</button>
      </footer>
    </div>
  </div>
)
}
export default Modal;
