
function FormField({label, children, classes='', target}) {
  return (
    <div className="field is-horizontal ">
      <div className="field-label is-normal">
        {label && <label className="label" htmlFor={target}>{label}</label>}
      </div>
      <div className="field-body">
        <div className={`field ${classes}`}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default FormField;
