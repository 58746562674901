export default function View({title, children}) {
  return (
    <section className="hero is-primary is-medium">
      <div className="hero-body">
        <div className="container has-text-centered box">
          <h1 className='is-size-3 mb-4'>{title}</h1>
          { children }
        </div>
      </div>
    </section>
  )
}