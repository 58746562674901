import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import _ from 'underscore'

import { RouterView, contentField, getAttr, queryParam } from '../utils';
import SelectionBox from '../components/SelectionBox';
import Nav from '../components/Nav';
import ContentWrapper from '../components/ContentWrapper';
import Toggleable from '../components/Toggleable';

function ResultsView({match: {params: { type }}, collection, collection: { loading }, fetchData, auth: {session, uid} }) {
  const [selected, setSelected] = React.useState([]);
  const [inclusive, setInclusive] = React.useState(false);
  const [tags, setTags] = React.useState(collection[`tags${type}`])
  const [src, setSrc] = React.useState(null)
  const isMobile = useMediaQuery({ query: '(max-width: 650px)' });
  const [descendants, setDescendants] = React.useState(null);
  const [parent, setParent] = React.useState(null);


  const reTag = () => {
    const kind = type || 'categories'
    const tagCategory =  type ? `tags${kind}` : kind
    // Get collection tags
    const oldTags = collection[tagCategory] ? Object.values(collection[tagCategory]) : []
    // Get content tags
    const contentTags = type ? _.uniq(contentField(collection[type], 'tags')) : []
    const tigCats = contentTags ? contentTags.map((tg) => Object.fromEntries([['name',tg]])) : [];
    // All category names
    const names = _.compact(contentField([...tigCats, ...oldTags], 'name'))
    // Fetch all tag data
    const allTags = names.map((name) => oldTags.find((a) => a.name === name && a.id ) || oldTags.find((a) => a.name === name) || tigCats.find((a) => a.name === name))
    setTags(() => setTags(_.sortBy(allTags, (item) => item.name ))) 
  }

  useEffect(() => {
    reTag()
  }, [collection, type])
  
  useEffect(() => {
    let field = queryParam('f', window.location.search) || 'category'

    let kind = type || 'categories'
    Promise.all([
      // fetchData(kind, `/q/?f=${field}&v=${kind}`, session)
      fetchData(kind, `/q/${kind}`, session)
    ]).then(() => {
      reTag();
    })

  }, [])

  useEffect(() => {
    const note = getAttr(collection['categories'], 'name', type) || getAttr(collection[type], 'id', type)
    const obj = getAttr(collection['categories'], 'name', type) // Items
    const parent = getAttr(collection['categories'], 'id', type) // Descendants Items draw name from
    setSrc(() => setSrc(note || parent))

    if (obj && obj.id)
      setDescendants( () => setDescendants({id: obj.id, content: collection[obj.id]}))
    if (parent) 
      setParent(() => setParent(parent))
  }, [collection, type])

  const selectionUpdated = (item) => {
    if (selected.includes(item)) 
      setSelected([...selected.filter((e) => e !== item)])
    else {
      setSelected([item, ...selected])
      if (!collection[item] && !type)
        fetchData(item, '/q/'+item, session)
    }
  }

  const name = parent ? `${parent.name} descendants` : type

  return (
    <div key={`${type}-results`}>
      {type && <Nav category={type} />}
      <section className="section is-small">
        <SelectionBox title={type ? `${name} Tags` : 'Categories'} 
          category={type || 'categories'} categories={tags && Object.values(tags)}
          selected={selected} setSelected={selectionUpdated}
          inclusive={inclusive} setInclusive={type ? setInclusive : null}
        />
      { type && <ContentWrapper 
        title={name} loading={loading} route={type} category={type} uid={uid} 
        content={collection[type || 'categories']} isMobile={isMobile}
        path={src ? `${src.category}/${src.id}` : null} 
        selected={selected} inclusive={inclusive}/>
      }
      { descendants && !!descendants.id && <Toggleable linkText='View Descendants' content={
        <ContentWrapper key={'descendants-'+type} loading={loading} route='categories' 
          title='Descendants' category={descendants.id} uid={uid} 
          content={descendants.content} isMobile={isMobile}
          selected={selected} inclusive={inclusive}/>
      }/>}
      { !type && selected.map((sel) => <ContentWrapper key={'t-'+sel} loading={loading} route={'categories'} category={sel} uid={uid} content={collection[sel]} isMobile={isMobile}/>)}
      </section>
    </div>
  );
}

export default RouterView(ResultsView);
