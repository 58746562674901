import React from 'react'
import Table from './Table';
import Loading from './Loading';
import { VISIBLE_FIELDS as headers } from '../reducers/noteReducer'

export default function TableWrapper({category, uid, content, loading}) {
  if( !content && loading)
    return <Loading text='Loading Data'/>

  return (
    <React.Fragment>
      <Table key={category} hideHeaders headers={headers} content={content} category={category} owner={uid} loading={loading}/>
    </React.Fragment>
  )
}