function Nav({category, slug}) {
  return (
  <nav className="breadcrumb has-arrow-separator is-centered" aria-label="breadcrumbs">
    <ul>
      <li><a href="/">Home</a></li>
      <li className={!slug ? 'is-active': ''}><a aria-current={!slug ? "page" : ''} href={!!slug ? `/${category}` : '#'}>{category || 'Category'}</a></li>
      {slug && <li className="is-active"><a href="#" aria-current="page">{slug || 'Thing'}</a></li>}
    </ul>
  </nav>
  );
}

export default Nav;
