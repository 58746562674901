function Button({classes, onClick, text, disabled, type, icon, noGroup=false, flip=false}){
  if(icon) icon = <span className="icon"><i className={`${icon} fa-lg`}/></span>

  const props = {
    className: `button ${classes}`,
    disabled: disabled || false,
    type: type || 'button',
    onClick
  }

  const link = (content) => (
    <button {...props}>
      { content }
    </button>
  )

  if (noGroup && !icon) return <div>{link(text)}</div>
  if (noGroup && !text) return <div>{link(icon)}</div>
  if (noGroup) return flip ? link(<span>{icon}<span>{text}</span></span>) : link(<span><span>{text}</span>{icon}</span>)

  if (!text && icon)
    return (
      <p className='buttons'>
        { link(icon) }
      </p>
    )

  return (
    <p className='buttons'>
      { flip && link(
        <span><span>{text}</span>{icon}</span>
      ) }
      { !flip && link(
        <span>{icon}<span>{text}</span></span>
      ) }
    </p>
  )
}

export default Button;