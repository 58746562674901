import Footer from './components/Footer'
import RouterNav from './components/RouterNav';
import MenuBar from './components/MenuBar';

function App() {
  return (
    <div>
      <div className="container">  
        <RouterNav nav={
          <div>
            <MenuBar/>
          </div>
        }/>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
