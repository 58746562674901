import LinkButton from "./LinkButton";
import DeleteButton from './DeleteButton'
import _ from 'underscore'
const TITLE_LENGTH = 20

function Table({headers, content, category, owner, hideHeaders=false}) {
  const renderFieldForItem = (item, key) => {
    const val = item[key] || ''
    if (key === 'tags' && val)
      return <div className='tags'>{val.map((n) => <span key={n} className={'block tag is-small'}>{n}</span>)}</div>;
    if (typeof(val) === 'object' )
      return <div>{JSON.stringify(val)}</div>
    if (key === 'name' && val) {
      let title = val.substring(0, Math.min(TITLE_LENGTH, val.length))
      if (title.length < val.length) title += '...' 
      return <LinkButton to={`/${category}/${item.id ? item.id : 'new?name='+item.name}`} text={title} classes='is-ghost'/>
    }
    if (key === 'edit' && owner === item.owner)
      return <LinkButton to={`/${category}/${item.id}/edit`} classes='is-link is-info is-inverted' icon='fas fa-pen-to-square'/>
    if (key === 'delete')
      return <DeleteButton hideText category={category} id={item.id} owner={item.owner} />
      
    if (key === 'value')
      return <div className='content' dangerouslySetInnerHTML={{__html: val}}/>
    return val
  }

  return (
  <table className="note-table table">
    <thead>
      <tr>
        { headers.map((head) => <th key={'th'+head}>{!hideHeaders ? head : ''}</th>) }
      </tr>
    </thead>
    { content && content.length > 40 &&
      <tfoot>
        <tr>
          { headers.map((head) => <th key={'tf'+head}>{head}</th>) }
        </tr>
      </tfoot>
    }
    <tbody>
      { content && _.sortBy(content, (item) => item.name ).map((item) =>   
        <tr key={'tr'+(item.id || item.name)}>{ headers.map((field) => <td key={`${item.name}-${field}`}>{renderFieldForItem(item, field)}</td>) }</tr>
      )}
      { !content && <tr className='has-text-centered'><td>No Content</td></tr>}
      { !!content && content.length < 1 && <tr className='has-text-centered'><td>No Results</td></tr>}
    </tbody>
  </table>
  )
};
export default Table;