import Button from "./Button"
import { highlightTarget } from '../utils'

const REACT_APP_HOST = process.env.REACT_APP_HOST;

export default function AccessBox({pid, category, makeLink, deleteLink}){
  return(
    <div className="field is-horizontal">
      <div className="field-label is-normal">
      {pid && <label className="label">Public link</label>}
      </div>
      <div className="field-body">
        <div className="field has-addons">
          <div className="control is-expanded">
            { typeof(pid) === 'boolean' && <input id='input' className="input" type="text" value='Loading...' readOnly />}
            { typeof(pid) === 'string' && <input id='input' className="input" type="text" value={`${REACT_APP_HOST}/@/${category}/${pid}?format=html`} readOnly onClick={() => highlightTarget('input')}/>}
          </div>
          <div className="control">
            {!pid && <Button text='Make public link' noGroup classes='is-info' icon='fas fa-globe' onClick={makeLink}/>}
            {pid && <Button noGroup classes='is-info' icon='fas fa-arrows-rotate' onClick={makeLink}/>}
          </div>
          <div className="control">
            {pid && <Button noGroup classes='is-danger' icon='fas fa-trash' onClick={deleteLink}/>}
          </div>
        </div>
      </div>
    </div>
  )
}