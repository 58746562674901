import React from 'react'
import Button from '../components/Button'

export default function Toggleable({linkText, content, callback}) {
  const [shown, setShown] = React.useState(false)
  const click = (state) => {
    setShown(state)
    callback && callback(state)
  }

  if (shown)
    return (
      <React.Fragment>
        <Button noGroup onClick={() => click(!shown)} icon='fas fa-xmark'
          classes='is-pulled-right is-medium is-info is-inverted' />
        {content}
      </React.Fragment>
    )
  return <Button classes='is-ghost' text={linkText} onClick={() => click(!shown)}/>
}