export default function Flexbox({classes, justifyContent, flexDirection, alignItems, children, flexWrap}) {
  return (
    <div className={classes} style={{
      display:'flex', 
      flexFlow: 'row', 
      flexWrap: flexWrap || 'nowrap',
      justifyContent: justifyContent || 'space-evenly',
      flexDirection: flexDirection || 'row',
      alignItems: alignItems || 'center'
      }}>
      {children}
    </div>
  )
}