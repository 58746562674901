import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import MessageWrapper from './MessageWrapper'
import routes from '../data/routes'
import {StateView, queryParam} from '../utils'

function PrivateRoute ({component: Component, loggedIn, redirect, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => !!loggedIn
        ? <Component {...props} />
        : <Redirect to={{pathname: redirect, state: {from: props.location}}} />}
    />
  )
}

function HashRoute ({ component: Component, hash, ...rest }) {
  return (
    <Route
      {...rest}
      component={({ location, ...props }) =>
        location.hash.includes(hash) && <Component {...props} />
      }
    />
  )
}

function RouterNav({nav, loggedIn, format, setFormat}) {
  const links = () => (
    routes.map((prop, key) => {
      const { hash, name, redirect, path, component } = prop

      if (prop.private)
        return <PrivateRoute loggedIn={loggedIn} exact path={path} redirect={redirect || '/login'}
                  key={`${name}${key}`} component={component} />;
      if (hash)
        return <HashRoute exact hash={hash} path={path} redirect={redirect || '/login'}
                  key={`${name}${key}`} component={component} />;
      return <Route exact path={path} key={`${name}${key}`} component={component} />;
    })
  );

  React.useEffect( () => {
    const newFormat = queryParam('format', window.location.search)
    if (format !== newFormat) setFormat(newFormat)
  }, [])

  return (
    <BrowserRouter>
      {( window.location.pathname !== '/welcome' && !format) && nav}
      <MessageWrapper/>
      <Switch>
        { links() }
      </Switch>
    </BrowserRouter>
  );
}

export const mapStateToProps = () => ({auth, note}) => ({ loggedIn: !!auth.session, format: note.format });
export default StateView(RouterNav, mapStateToProps);
