import React from 'react'
import Note from '../components/Note'
import { RouterView } from '../utils';

function PublicView({note, note: {format}, publicLink, match: {params: { pid, type }}}) {
  
  React.useEffect( () => {
    publicLink(pid, type)
  },[])

  return (
    <div className='container'>
      <Note {...{format, ...note}}/>
    </div>
  )
}

export default RouterView(PublicView);
